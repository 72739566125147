// Footer.js

import React from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import { FaMapMarkerAlt, FaMobileAlt, FaFacebook, FaInstagram } from 'react-icons/fa';

const Footerlanding = () => {
  return (
    <Box width="100%">
      <Flex
        bg="black"
        justifyContent="space-evenly"
        h={{ base: 'auto', md: '50%' }}
        p={{ base: 2, md: 4 }}
        direction={{ base: 'column', md: 'row' }}
        wrap="nowrap"
      >
        <Text color="white" fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold" mb={{ base: 2, md: 0 }}>
          Address
        </Text>
        <Flex color="white" align="center" mb={{ base: 2, md: 0 }}>
          <FaMapMarkerAlt  size={20}/>
          <Text fontSize={{ base: 'sm', md: 'md' }}>
            Immeuble Ben Nahia, près de l'hôpital Tahar Sfar, 5111
          </Text>
        </Flex>

        <Text color="white" fontSize={{ base: 'md', md: 'lg' }} fontWeight="bold" mb={{ base: 2, md: 0 }}>
          Mobile :   98 709 994
        </Text>
       

        {/* Icons for Facebook and Instagram */}
        <Flex color="white">
          <Box mr={{ base: 2, md: 4 }}>
            <a href="https://www.facebook.com/BenNahiaMedicoSpa" target="_blank" rel="noopener noreferrer">
              <FaFacebook size={20} />
            </a>
          </Box>
          <Box>
            <a href="https://www.instagram.com/centre_ben_nahia" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={20} />
            </a>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footerlanding;
