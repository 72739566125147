//zid el el recherche w zid el get medical care bech tekhou el link mtaa el file walla orbtou bel user zeda



import React, { useEffect, useState } from 'react';
import { Box, Button, Table, Thead, Tbody, Tr, Th, Td, Text, Input, useToast, Checkbox, Spinner, Flex } from '@chakra-ui/react';
import { FiEdit2 } from 'react-icons/fi';
import apiClient from 'views/admin/appclient';
import { FaCheck, FaTimes } from 'react-icons/fa';
type ScheduleEntry = {
    daysOfWeek: string[];
    startHour: string;
    scpaied: boolean;
    notes : string;
    endHour: string;
    presencePatient: any;
   _id: string;
   [key: string]: any; // Index signature to allow any other properties

  };
  
  type ScheduleTableProps = {
    visitsId: string;
    currentVisits: ScheduleEntry[];
    currentPage: number;
    visitsPerPage: number;
    handlePageChange: (newPage: number) => void;
    refresh : () => void;
  };
  
  const ScheduleTable: React.FC<ScheduleTableProps> = ({
    visitsId,
    currentVisits,
    currentPage,
    visitsPerPage,
    handlePageChange,
    refresh,
  }) => {
    const [searchDate, setSearchDate] = useState<string>('');

    const [editedEntries, setEditedEntries] = useState<{ [index: number]: Partial<ScheduleEntry> }>({});
    const toast = useToast();
    const initialLoadingStates: { [index: number]: boolean } = {};
    const [loading, setLoading] = useState<{ [index: number]: boolean }>(initialLoadingStates);

    useEffect(() => {
      const initializeEditedEntries = currentVisits.reduce((acc, entry, index) => {
        acc[index] = { 
          presencePatient: entry.presencePatient, 
          scpaied: entry.scpaied, 
          notes: entry.notes,
          startHour: entry.startHour,
          endHour: entry.endHour
        }; 
        return acc;
      }, {} as { [index: number]: Partial<ScheduleEntry> });
      setEditedEntries(initializeEditedEntries);
    }, [currentVisits]);
    
    const handleEditSchedule = async (visitId: string, scheduleId: string, editedData: Partial<ScheduleEntry>, index: number) => {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
    
      try {
        const originalData = currentVisits.find((entry) => entry._id === scheduleId) as ScheduleEntry;
        const mergedData: Partial<ScheduleEntry> = { ...originalData, ...editedData };
    
        // Ensure daysOfWeek is always an array
        const daysOfWeekArray = Array.isArray(mergedData.daysOfWeek) ? mergedData.daysOfWeek : [mergedData.daysOfWeek];
    
        // Transform daysOfWeek format to include day of the week
        const transformedData: Partial<ScheduleEntry> = {
          ...mergedData,
          daysOfWeek: daysOfWeekArray.map(getFormattedDateWithDay),
        };
    
        setLoading((prevLoadingStates) => ({ ...prevLoadingStates, [index]: true }));
    
        const response = await apiClient.patch(
          `/api/visits/schedules/${visitId}/${scheduleId}`,
          transformedData,
          { headers }
        );
    
        toast({
          title: "Mise à jour du planning",
          description: "Le planning a été mis à jour avec succès.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error editing schedule:', error);
        toast({
          title: "Erreur lors de la mise à jour du planning",
          description: "Une erreur est survenue lors de la mise à jour du planning.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading((prevLoadingStates) => ({ ...prevLoadingStates, [index]: false }));
        refresh(); 
      }
    };
    
    

  const handleTogglePresence = (index: number) => {
    setEditedEntries({
      ...editedEntries,
      [index]: {
        ...editedEntries[index],
        presencePatient: !editedEntries[index]?.presencePatient,
      },
    });
  };
  const handleToggleScpaied = (index: number) => {
    setEditedEntries(prevState => ({
        ...prevState,
        [index]: {
            ...prevState[index],
            scpaied: !prevState[index]?.scpaied,
        },
    }));
};

    const handleInputChange = (index: number, field: keyof ScheduleEntry, value: string) => {
        setEditedEntries({
      ...editedEntries,
      [index]: {
        ...editedEntries[index],
        [field]: value,
      },
    });
  };
  if (!currentVisits) {
    return <Spinner size="lg" />;
  }

  const filteredVisits = currentVisits.filter(entry => {
    const entryDate = new Date(getFormattedDate(entry.daysOfWeek[0])).toISOString().split('T')[0];
    return searchDate === '' || entryDate === searchDate;
});
const handleResetSearch = () => {
  setSearchDate('');
};
const handleNotesChange = (index: number, value: string) => {
  setEditedEntries(prev => ({
    ...prev,
    [index]: {
      ...prev[index],
      notes: value, // This will set notes to an empty string if the value is empty
    },
  }));
};
  return (
    <>
        <Box my={4}>
          <Flex>
            <Input
                type="date"
                placeholder="Rechercher par date..."
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
            />
             <Button ml={2} onClick={handleResetSearch}>
          Réinitialiser
        </Button>
        </Flex>
        </Box>
        {filteredVisits.length > 0 && (
            <>
                <Text mt={4} fontWeight="bold" fontSize="lg">Planning:</Text>
                <Table variant="simple" mt={2}>
                    <Thead>
                        <Tr style={{ backgroundColor: "#F5F5F5", textAlign: "center" }}>
                            <Th>Jour</Th>
                            <Th>Début</Th>
                            <Th>Fin</Th>
                            <Th>Notes</Th>
                            <Th>Présence</Th>
                            <Th>Séance payé</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredVisits.map((scheduleEntry, index) => (
                            <Tr key={index}>
                                <Td>
                                    <Input
                                        type="date"
                                        value={editedEntries[index]?.daysOfWeek || getFormattedDate(scheduleEntry.daysOfWeek[0])}
                                        onChange={(e) => handleInputChange(index, 'daysOfWeek', e.target.value)}
                                    />
                                </Td>
                                <Td>
                                    <Input
                                        type="time"
                                        value={editedEntries[index]?.startHour || scheduleEntry.startHour}
                                        onChange={(e) => handleInputChange(index, 'startHour', e.target.value)}
                                    />
                                </Td>
                                <Td>
                                    <Input
                                        type="time"
                                        value={editedEntries[index]?.endHour || scheduleEntry.endHour}
                                        onChange={(e) => handleInputChange(index, 'endHour', e.target.value)}
                                    />
                                </Td>
                                <Td>
                                <Input
                                  h="70px"
                                  w="200px"
                                  type="text"
                                  value={editedEntries[index]?.notes }
                                  onChange={(e) => handleNotesChange(index, e.target.value)}
                                />
                              </Td>

                                <Td>
                                    <Button
                                        variant="ghost"
                                        color={editedEntries[index]?.presencePatient ? 'green' : 'red'}
                                        onClick={() => handleTogglePresence(index)}
                                    >
                                        {editedEntries[index]?.presencePatient ? (
                                            <FaCheck size={20} />
                                        ) : (
                                            <FaTimes size={20} />
                                        )}
                                    </Button>
                                </Td>
                                <Td>
                                    <Button
                                        variant="ghost"
                                        color={editedEntries[index]?.scpaied ? 'green' : 'red'}
                                        onClick={() => handleToggleScpaied(index)}
                                    >
                                        {editedEntries[index]?.scpaied ? (
                                            <FaCheck size={20} />
                                        ) : (
                                            <FaTimes size={20} />
                                        )}
                                    </Button>
                                </Td>
                                <Td>
                                    {loading[index] ? (
                                        <Spinner size="lg" color="blue.500" />
                                    ) : (
                                        <Button
                                            onClick={() =>
                                                handleEditSchedule(visitsId, scheduleEntry._id, editedEntries[index], index)
                                            }
                                        >
                                            <FiEdit2 />
                                        </Button>
                                    )}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>

                <Box mt={4} textAlign="center">
                    <Button
                        isDisabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                        mr={2}
                    >
                        Précédent
                    </Button>
                    <Button
                        isDisabled={filteredVisits.length < visitsPerPage}
                        onClick={() => handlePageChange(currentPage + 1)}
                    >
                        Suivant
                    </Button>
                </Box>
            </>
        )}
    </>
);
};



export default ScheduleTable;
const getFormattedDate = (fullDateString: string): string => {
  const matchDate = fullDateString.match(/^(\d{4}-\d{2}-\d{2})$/);
  const matchDateString = fullDateString.match(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);
  
  if (matchDate) {
    return matchDate[0]; // Return the full string if it matches the "yyyy-MM-dd" format
  } else if (matchDateString) {
    const day = matchDateString[1].padStart(2, '0');
    const month = matchDateString[2].padStart(2, '0');
    const year = matchDateString[3];
    // Return the date in "yyyy-MM-dd" format
    return `${year}-${month}-${day}`;
  } else {
    return ''; // Handle the invalid case accordingly
  }
};

  
const getFormattedDateWithDay = (dateString: any): string => {
  // Regular expression to check if the string already contains a day name
  const dayPattern = /^(Dimanche|Lundi|Mardi|Mercredi|Jeudi|Vendredi|Samedi)/;

  // If the day name is found in the date string, return it as it is
  if (dayPattern.test(dateString)) {
    return dateString;
  }

  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return ''; // Handle invalid date case
  }

  const daysOfWeek = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
  const dayName = daysOfWeek[date.getDay()]; // Use getDay() instead of getUTCDay() for local time zone

  const options: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedDate = date.toLocaleDateString('fr-FR', options);

  return `${dayName} ${formattedDate}`;
};
