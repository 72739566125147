import React, { SetStateAction, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  ChakraProvider,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Image, 
   VStack ,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import { HSeparator } from "components/separator/Separator";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { Carousel } from "react-responsive-carousel";
import apiClient from "../../admin/appclient";
// index.js or index.tsx (or another global styles file)
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Footerlanding from "./footerlanding";
import CustomCarousel from "./carousel";
function SignIn() {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const addToast  = useToast();
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!email || !password) {
      addToast({
        title: "Login failed",
        description: "Email and password are required.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true);
    // Call your handleSignIn function here
    try {
      // Call your handleSignIn function here
      await handleSignIn();
    } finally {
      // Reset loading state to false, whether the request succeeds or fails
      setIsLoading(false);
    }
  };
  const [isLoading, setIsLoading] = useState(false);
  const handleSignIn = async () => {
    try {
      const response = await apiClient.post("/api/user/login", {
        email,
        password,
      });
   
      if (response.status === 200) {
        console.log(response.data);
        if (response.data.accessToken) {
          localStorage.setItem("token", response.data.accessToken);
          localStorage.setItem("authToken", response.data.accessToken);
          localStorage.setItem("token", response.data.accessToken);
          history.push('/admin/default');
        } else {
          console.log("Token not found in the response");
        }
      } else if (response.status === 442 && Array.isArray(response.data.errors)) {
        // Handle array of errors
        const errorMessages = response.data.errors.map((error:any) => error.msg);
        addToast({
          title: 'Login failed',
          description: errorMessages.join('\n'),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {

        addToast({
          title: response.data.message || 'Login failed',
          description: "You have been successfully logged out.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        console.log("Login failed");
      }
    } catch (error: any) {
      // Handle network or request error.
      console.error(error);
      addToast({
        title: `Login failed ${error.response?.data?.message}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      console.log("Login failed");
    }
  };

const homeRef = useRef<HTMLDivElement | null>(null);
  const faqRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleCarouselChange = (index: SetStateAction<number>) => {
    setSelectedIndex(index);
  };
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  const handleSignInButtonClick = () => {
    setIsSignInModalOpen(true);
  };

  const handleCloseSignInModal = () => {
    setIsSignInModalOpen(false);
  };
  const carouselImages = ['https://scontent.ftun8-1.fna.fbcdn.net/v/t39.30808-6/217734320_284848506765076_5538680440810609680_n.jpg?_nc_cat=105&ccb=1-7&_nc_sid=5f2048&_nc_ohc=k4L0w_vw6QgAX9LwcmU&_nc_ht=scontent.ftun8-1.fna&oh=00_AfC346GG0aXEWcCHOAJ6Zj9DgAmrawcyzQycLdVTeQ6dFw&oe=655F437B','https://plurielle.tn/pro/wp-content/uploads/2022/06/photo-67843.jpeg', 'https://plurielle.tn/pro/wp-content/uploads/2022/06/photo-67838.jpeg'];
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const textOverlayStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: 'white',
  };

  return (
    <ChakraProvider>
      {/* Navbar */}
      <Flex
      as="nav"
      align="center"
      justifyContent="space-between"
      wrap="wrap"
      padding="1rem"
      bg="black"
      color="white"
      >
        <Box display="flex" alignItems="center">
      <img
        src="/images/bennahialogo.png"
        alt="Logo"
        style={{ width: '15%', height: '15%', marginRight: '10px' }}
      />
      <Text fontWeight="bold" fontSize="lg">Bienvenue à Centre Ben Nahia</Text>
    </Box>
        <Flex
     justify="end"
     
     align="center"
     justifyContent="space-between"
     wrap="wrap"
        >
        <NavLink to="/">
          <Text fontSize="lg" fontWeight="bold" onClick={() => scrollToSection(homeRef)} mr={4}>
            Accueil
          </Text>
        </NavLink>
        <NavLink to="/faq">
          <Text fontSize="lg" fontWeight="bold" onClick={() => scrollToSection(faqRef)}  mr={4}>
            FAQ
          </Text>
        </NavLink>
        <NavLink to="/contact">
          <Text  onClick={() => scrollToSection(footerRef)} fontSize="lg" fontWeight="bold"  mr={4}>
            Nous Contacter
          </Text>
        </NavLink>
        <Button backgroundColor="#fddbd1" onClick={handleSignInButtonClick}>Sign In</Button>
        </Flex>
      </Flex>

      {/* Sign In Modal */}
      <Modal isOpen={isSignInModalOpen} onClose={handleCloseSignInModal}>
        <ModalOverlay />
        <ModalContent mt={5}>
          <ModalHeader>Ce conncter</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <form onSubmit={handleFormSubmit}>
          <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
    
        flexDirection="column"
      >
        <Box me="auto" >
          <Heading color={textColor} fontSize="36px" mb="10px">
          Vous pouvez vous connecter uniquement si vous avez déjà un compte administrateur ou d'équipe !
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Veuillez contacter l'administrateur pour créer un nouveau compte
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
      
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="Email address"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Mot de passe<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Mot de passe"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <Flex justifyContent="space-between" align="center" mb="24px">
            <FormControl display="flex" alignItems="center">
              <Checkbox id="remember-login" colorScheme="brandScheme" me="10px" />
              <FormLabel htmlFor="remember-login" mb="0" fontWeight="normal" color={textColor} fontSize="sm">
              Rester connecter
              </FormLabel>
            </FormControl>
            {/* <NavLink to="/auth/forgot-password">
              <Text color={textColorBrand} fontSize="sm" w="124px" fontWeight="500">
                Forgot password?
              </Text>
            </NavLink> */}
          </Flex>
          {isLoading ? (
             <Flex align="center" justify="center" direction="column">
                <Spinner  
                  thickness="3px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500" // Change this to your desired color
                size="xl" 
                justifyContent="center"
                alignContent="center"/>
              
              <Text mt="4" color="brand.500" fontWeight="bold">
              En cour...
                  </Text>
              </Flex>
              )
              : (
                <Button
                fontSize="sm"
                backgroundColor="#fddbd1" // Choose a color scheme that you like
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
              >
                Se connecter
              </Button>
              )}
        </Flex>
        {/* <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px">
          <Text color={textColorDetails} fontWeight="400" fontSize="14px">
            Not registered yet?
            <NavLink to="/auth/sign-up">
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                Create an Account
              </Text>
            </NavLink>
          </Text>
        </Flex> */}
      </Flex>
      </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex>
<Box width="100%"  ref={faqRef}>
      <CustomCarousel carouselImages={carouselImages} />
      </Box>
  
</Flex>
<Box
        background="#fff1f0"
        height={{ base: "auto", md: "50vh" }}
        mt={{ base: "-20px", md: "-10px" , sm:"5px"  , xs:"5px" , xl:"5px" , '2xl':"5px" }} 
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ base: "column", md: "row" }}
      >
      <Image src="/images/lef_1.png" alt="lef" display={{ base: "none", md: "block" }} />
      <VStack spacing={4} mr="1%" alignItems="center">
        <Box
          borderRadius="full"
          overflow="hidden"
          boxShadow="lg"
          width="100px"
          height="100px"
          bg="E7836F"
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="justify"
        >
          <Image src="/images/kine.png" alt="box" width="100%" height="100%"  />
        </Box>
        <Text textAlign="justify" color="black" >Un service de kinésithérapie professionnel pour vous aider dans votre rééducation.
           Nos kinés qualifiés travaillent avec vous pour atteindre vos objectifs de santé et de bien-être.</Text>
      </VStack>
      <VStack spacing={4}mr="1%" alignItems="center">
        <Box
          borderRadius="full"
          overflow="hidden"
          boxShadow="lg"
          width="100px"
          height="100px"
          bg="E7836F"
          display="flex"
  alignItems="center"
  justifyContent="center"
  textAlign="justify"
        >
          <Image src="/images/picsine.png" alt="box" width="80%" height="80%"  />
        </Box>
        <Text  textAlign="justify" color="black" >Profitez de notre piscine chauffée pour des moments de détente et d'exercice. La température agréable de l'eau vous offre une expérience de baignade confortable, que ce soit pour la thérapie ou simplement pour vous relaxer</Text>
      </VStack>
      <VStack spacing={4} alignItems="center">
        <Box
          borderRadius="full"
          overflow="hidden"
          boxShadow="lg"
          width="100px"
          height="100px"
          bg="E7836F"
          display="flex"
  alignItems="center"
  justifyContent="center"
  textAlign="justify"
        >
          <Image src="/images/Gym.png" alt="box" width="70%" height="70%" />
        </Box>
        <Text     textAlign="justify" color="black" >Notre petite salle de gym est équipée pour répondre à vos besoins d'entraînement. Des équipements soigneusement sélectionnés sont disponibles pour vous permettre de maintenir votre forme physique, dans un environnement confortable et adapté à vos besoins.</Text>
      </VStack>
      <Image src="/images/lef_2.png" alt="lef"  display={{ base: "none", md: "block" }} />
      <div><br/></div>
    </Box>
  
<Box width="100%" textAlign="center"  backgroundColor="pink.100" borderRadius="lg" boxShadow="lg" style={{ boxShadow: '4px 10px 4px rgba(0, 0, 0, 0.1)' }}>
  <Box mb={4}>
  <Box mb={4} p={4} borderRadius="lg" style={{ boxShadow: '-4px -10px 4px rgba(0, 0, 0, 0.1)' }}>
  <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
    Trouvez-nous plus rapidement sur la carte ci-dessous :
  </p>
  <hr style={{ borderColor: '#000', borderWidth: '2px', margin: '10px 0' }} />
</Box>


  </Box>
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3247.671187547153!2d11.032588174901012!3d35.512407939231025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x130223c8db3b79cf%3A0x42d75de554f131d5!2sCentre%20Ben%20Nahia%20Oussama(%20r%C3%A9%C3%A9ducation)!5e0!3m2!1sfr!2stn!4v1700483229895!5m2!1sfr!2stn"
    width="100%"
    height="400px"
    style={{ border: 0 }}
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
  ></iframe>
  <div> 
    <br></br></div> 
 
</Box>

      {/* FAQ Section */}
      <Flex ref={faqRef} direction="column" align="center" mt="8">
        <Heading mb="4">Foire Aux Questions</Heading>
        {/* Your FAQ content goes here */}
        <Text>FAQ content...</Text>
      </Flex>
 
  <Box ref={footerRef}>
  <Footerlanding />
  </Box>


 
    </ChakraProvider>
  );
};
export default SignIn;