// EventModal.js

import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Input, Progress, Button  , Text, Switch, Flex, Checkbox, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, useToast, Box, HStack, useColorModeValue} from "@chakra-ui/react";
import { api } from 'api';
import appclient from 'views/admin/appclient';
import ScheduleTable from 'views/admin/patients/components/ScheduleTable-Patient';

interface EventCardProps 
{
  isOpen: boolean;
  onClose: () => void;
  selectedEvent: { 
    _id : any ; 
    visitID : any ;
    title: string, 
    kine?: any, 
    medicalCareId?: string } | null;
}
const EventCard: React.FC<EventCardProps> = ({ isOpen, onClose, selectedEvent }) => {
  const [medicalCareDetails, setMedicalCareDetails] = useState<any>(null); 
  const [updatedAmountPay, setUpdatedAmountPay] = useState<string>('');
  const [progress, setProgress] = useState<number | null>(null);
  const [isPatientPresent, setIsPatientPresent] = useState<boolean>(false);
  const [isKinéPresent, setIsKinéPresent] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [visits, setVisits] = useState([]);
  const [editedScheduleId, setEditedScheduleId] = useState<string | null>(null);
  const [scheduledata , setScheduledata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const visitsPerPage = 5; 
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [isCash, setIsCash] = useState<boolean>(true);
  const toast = useToast();
useEffect(() => {
  fetchMedicalCareDetails();

},[selectedEvent?.medicalCareId ]);
useEffect (() => {
  if (selectedEvent) {
    getVisitForTable();
  }
} ,[selectedEvent ]);
  useEffect(() => {
    calculateProgress();
  }, [ medicalCareDetails, medicalCareDetails?.amountPay, medicalCareDetails?.amountToPay]);

  const fetchMedicalCareDetails = async () => {
    const token = localStorage.getItem("token");
    try {
      if (selectedEvent?.medicalCareId) {
        const response = await appclient.get(`api/charges/${selectedEvent.medicalCareId}`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        setMedicalCareDetails(response.data);
      }
    } catch (error) {
      console.error('Error fetching medical care details:', error);
    }
  };
  const calculateProgress = () => {
    if (medicalCareDetails?.amountPay !== undefined && medicalCareDetails?.amountToPay !== undefined && !Number.isNaN(medicalCareDetails?.amountToPay) ) {
      const calculatedProgress = (medicalCareDetails.amountPay / medicalCareDetails.amountToPay) * 100;
      setProgress(calculatedProgress);
    }else
    setProgress(0);
  };  
  const handleAmountPayUpdate = async () => {
    const token = localStorage.getItem("token");
  
    try {
        if (selectedEvent?.medicalCareId && updatedAmountPay !== '') {
            const amountPayValue = parseFloat(updatedAmountPay);
   
            const currentAmountPay = medicalCareDetails?.amountPay || 0;
            const remainingAmountToPay = medicalCareDetails?.amountToPay - currentAmountPay;
  
            if (amountPayValue > remainingAmountToPay) {
                const message = 'Le montant payé est supérieur au montant restant.';
                toast({
                    title: 'Error',
                    description: message,
                    status: 'error',
                    duration: 5000, // Toast will be displayed for 5 seconds
                    isClosable: true,
                });
                return;
            }
            
            const newAmountPay = currentAmountPay + amountPayValue;
            const paymentType = isCash ? 'cheque' : 'cash';
            await appclient.post("/api/payment/", {
                medicalCare: medicalCareDetails._id,
                patient: medicalCareDetails.patient?.[0]._id,
                amountPaid: amountPayValue,
                amountBefore: currentAmountPay,
                amountAfter: newAmountPay,

                amountToPay: medicalCareDetails?.amountToPay,
                type: paymentType, // Assuming type is always 'cash'
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
  
            // Send PATCH request to update the amountPay in the charges
            await appclient.patch("api/charges/update", {
                _id: medicalCareDetails._id,
                amountPay: newAmountPay,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
        
         fetchMedicalCareDetails();
        }
    } catch (error) {
        console.error('Error updating amountPay:', error);
        // Handle error as needed
    } 
};

  
  const handlePatientPresence = async () => {
    try {
      const token = localStorage.getItem("token");
      const updatedPresenceData = {
        presencePatient: !isPatientPresent,
      };
  
      await appclient.patch(`api/visits/${selectedEvent?.visitID}/${selectedEvent?._id}`, updatedPresenceData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      // Update the local state after successful server update
      setIsPatientPresent(!isPatientPresent);
    } catch (error) {
      console.error('Error updating presence:', error);
      // Handle error (e.g., show a user-friendly error message)
    }
  };
  
  // const handleKinéPresence = async () => {
  //   try {
  //     const token = localStorage.getItem("token");
  //     const updatedPresenceData = {
  //       presenceKine: !isKinéPresent,
  //     };

  //     await appclient.patch(`api/visits/${selectedEvent?.visitID}/${selectedEvent?._id}`, updatedPresenceData, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-Type': 'application/json',
  //       },
  //     });
  //     setIsKinéPresent(!isKinéPresent);
  //   } catch (error) {
  //     console.error('Error updating presence:', error);
  //   }
 
  // };
  const [visitsTB, setVisitsTB] = useState<any>([]);
  const getVisitForTable = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await appclient.get(`api/visits/${selectedEvent?.visitID}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setVisitsTB(response.data);
   
   setScheduledata(response.data.schedule);  

   setLoading(false);
    } catch (error) {
      console.error('Error fetching visits:', error);
    }
  }
  const handlePaymentTypeToggle = () => {
    setIsCash(!isCash);
};
const refreshScheduleTable = () => {
  fetchMedicalCareDetails();
  getVisitForTable();
};
  const indexOfLastVisit = currentPage * visitsPerPage;
  const indexOfFirstVisit = indexOfLastVisit - visitsPerPage;
  const currentVisits = scheduledata.slice(indexOfFirstVisit, indexOfLastVisit);
  const totalPages = Math.ceil(visitsTB?.schedule?.length / visitsPerPage)
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
      <ModalHeader style={{ display: 'flex' }}>
  <span style={{ marginRight: '15%' }}>{selectedEvent?.title}</span>
  {medicalCareDetails?.file ? (
    <a href={medicalCareDetails.file} target="_blank" rel="noopener noreferrer">
      <button>
        AP4
      </button>
    </a>
  ) : (
    ""
  )}
</ModalHeader>


   <ModalCloseButton />
        <ModalBody>
          <div>
            <p>Kiné :  {medicalCareDetails?.kine[0].firstName}</p>
          </div>
          <div>
            <p>Pathologie : {medicalCareDetails?.treatment?.[0]?.name || 'no treatment'}</p>
          </div>
          <p> Médecin : {medicalCareDetails?.doctor[0]?.nom} </p>
          <div>
  {medicalCareDetails && (
    <p>progrès de paiement {medicalCareDetails?.amountPay ?? 0}/{medicalCareDetails?.amountToPay} DT</p>

  )}
  <div></div>
  <Flex direction="column" alignItems="center">
    <Progress hasStripe value={progress} colorScheme="blue" size="lg" width="full" />
    <Text mt={2} fontSize="lg" fontWeight="bold">
      {progress?.toFixed(1)}%
    </Text>
  </Flex>
</div>

          <div>
          <p> 
            Ajouter un montant </p>
          <Flex>
         
          <NumberInput
  defaultValue={1}
  min={1}
  max={999}
  step={1}
  precision={0}
>
<NumberInputField
  placeholder="Enter le montant a ajouter"
  value={updatedAmountPay}
  onChange={(event) => {
    const valueString = event.target.value;
    const inputValue = parseFloat(valueString);
    if (!isNaN(inputValue) && inputValue >= 0) { 
      setErrorMessage('');
      setUpdatedAmountPay(inputValue.toString()); 
    } else {
      setUpdatedAmountPay(''); 
      setErrorMessage('Please enter a valid amount.'); 
    }
  }}
/>


</NumberInput>
<Flex>
<Flex justify="space-between" alignItems="center" mb="4" margin="1%"  position="relative">
    <Text margin="5%">Type:</Text>
 <Flex justify="space-between" alignItems="center" mb="4" margin="1%" flexDirection="row">   
       <Text > {isCash ? 'Chéque' : 'Cash'}</Text>
       <Switch
        colorScheme="blue"
        isChecked={isCash}
        onChange={handlePaymentTypeToggle}
        margin="5%"
      
    >
    </Switch>
    </Flex>
</Flex>

{errorMessage && <Text color="red">{errorMessage}</Text>}
            <Button marginLeft={"15%"}  marginRight={"5%"} position="relative" onClick={handleAmountPayUpdate}>Ajouter montant</Button></Flex>
         </Flex>
           
          </div>
          {!loading && (
  <Box>
    {visitsTB.length === 0 && (
      <Text color={textColor} mt={4}>
        Pas de rendez-vous pour le moment.
      </Text>
    )}

{visitsTB  && (
  <ScheduleTable
    visitsId={visitsTB.id
    }
    currentVisits={currentVisits}
    currentPage={currentPage}
    visitsPerPage={visitsPerPage}
    handlePageChange={handlePageChange}
    refresh={refreshScheduleTable} 
  />
)}

  {!loading && (
  <HStack spacing={4} mt={4} justifyContent="center">
    {Array.from({ length: totalPages }, (_, index) => {
      // Display only the current page and its neighbors
      const isWithinRange = Math.abs(currentPage - (index + 1)) <= 1;
      
      if (isWithinRange || index === 0 || index === totalPages - 1) {
        return (
          <Button
            key={index + 1}
            colorScheme={index + 1 === currentPage ? 'blue' : 'gray'}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </Button>
        );
      }
      
      // Display ellipsis for pages that are not within the range
      if (!isWithinRange && index === 1) {
        return <Text key="ellipsis-start">...</Text>;
      }
      if (!isWithinRange && index === totalPages - 2) {
        return <Text key="ellipsis-end">...</Text>;
      }
      
      return null;
    })}
  </HStack>
)}

  </Box>
)}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EventCard;
