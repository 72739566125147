import React, { useEffect, useState } from "react";
import { Box, Button, Flex, SimpleGrid } from "@chakra-ui/react";
import PECTable from "./Components/PECTable";
import appclient from "views/admin/appclient";


export default function PEC() {
  const [PEC, setPEC] = useState([]);
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const [isPECModalOpen, setPECModalOpen] = useState(false);

  const openPECModal = () => {
    setPECModalOpen(true);
  };

  const closePECModal = () => {
    setPECModalOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await appclient.get("/api/charges", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPEC(response.data.items);
      } catch (error) {
        console.error("Error fetching medical care records:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Box pt={{ base: "auto", md: "80px", xl: "80px" , lg :"auto" }}   w={{ base: "0px", md: "0px", xl: "100%" , lg :"auto" }}> 
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
         
        <PECTable pecRecords={PEC} /> 
        <Flex mt="20px" justifyContent="space-between">

        </Flex>
      </SimpleGrid>
  
    </Box>
  );
}
