import React, { useState, useEffect, useRef } from 'react';

import './landing.css';
interface CustomCarouselProps {
    carouselImages: string[];
    
  }

const CustomCarousel :React.FC<CustomCarouselProps> = ({ carouselImages }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselInnerRef = useRef<HTMLDivElement>(null);
  
  const showSlide = (index: number) => {
    const newTransformValue = -index * 100 + '%';
    carouselInnerRef.current.style.transform = 'translateX(' + newTransformValue + ')';
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
    prevIndex + 1 === carouselImages.length ? 0 : prevIndex + 1
  );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
    prevIndex - 1 < 0 ? carouselImages.length - 1 : prevIndex - 1
  );
  };
  const handleDotClick = (index : any) => {
    setCurrentIndex(index);
  };

  // Automatically switch to the next slide every 4000 milliseconds
  useEffect(() => {
    const intervalId = setInterval(nextSlide, 4000);
    return () => clearInterval(intervalId);
  }, [currentIndex]);

  
  return (
    <div className="carousel">
        <img
        className='carousel-images'
          key={currentIndex}
          src={carouselImages[currentIndex]}
        />
         <div className="indicator">
        {carouselImages.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
          ></div>
        ))}
      </div>
      <div className="slide_direction">
        <div className="left" onClick={prevSlide}>
            <path d="M400 976 0 576l400-400 56 57-343 343 343 343-56 57Z" />     
        </div>
        <div className="right" onClick={nextSlide}>
    
            <path d="m304 974-56-57 343-343-343-343 56-57 400 400-400 400Z" />

        </div>
        
      </div>
     
    </div>
  );
};

export default CustomCarousel;

